import ReactGA from "react-ga4";
import { Heap } from "@heap/heap-capacitor-bridge";
import { AppState } from "../../persistence/Persistance";
import { isProductionEnvironment, isWebsiteEnvironment } from "../../utils/StagingUtils";

export class AnalyticsService {
  private static instance: AnalyticsService;
  private googleAnalytics: GoogleAnalyticsService;
  private heapAnalytics: HeapAnalyticsService;

  private constructor() {
    this.googleAnalytics = new GoogleAnalyticsService();
    this.heapAnalytics = new HeapAnalyticsService();
  }

  public static getInstance(): AnalyticsService {
    if (!AnalyticsService.instance) {
      AnalyticsService.instance = new AnalyticsService();
    }
    return AnalyticsService.instance;
  }

  public recordState(appState: AppState) {
    this.googleAnalytics.recordState(appState.state);
    this.heapAnalytics.recordState(appState);
  }
}

class GoogleAnalyticsService {
  private trackingId: string;
  private initialized: boolean = false;

  constructor() {
    this.trackingId = isProductionEnvironment ? "G-VHL8Z7JCP7" : "G-8ZYH37DDL2";
    this.initialized = this.initialize();
  }

  private initialize(): boolean {
    try {
      console.log("Initializing ReactGA with tracking ID: " + this.trackingId);
      ReactGA.initialize(this.trackingId);
      return true;
    } catch (e) {
      console.error("Failed to initialize ReactGA: " + e);
      return false;
    }
  }

  public recordState(state: string) {
    if (!this.initialized) return;

    try {
      console.log("ReactGA.event with tracking ID: " + this.trackingId);
      ReactGA.event({
        category: "Navigation",
        action: "Navigate to " + state,
      });
      ReactGA.send({ hitType: "pageview", page: state });
    } catch (e) {
      console.error("Failed to save app state to Google Analytics: " + e);
    }
  }
}

class HeapAnalyticsService {
  private trackingId: string = "2419562405";
  private initialized: boolean = false;

  constructor() {
    this.initialized = this.initialize();
  }

  private initialize(): boolean {
    if (isWebsiteEnvironment) return false;

    try {
      console.log("Initializing Heap with tracking ID: " + this.trackingId);
      Heap.startRecording(this.trackingId, {
        captureUncaughtExceptions: true,
        captureUnhandledRejections: true,
      });
      return true;
    } catch (e) {
      console.error("Failed to initialize Heap: " + e);
      return false;
    }
  }

  public recordState(appState: AppState) {
    if (!this.initialized) return;

    try {
      Heap.track("Updated appState", {
        state: appState.state,
        round: appState.tournament.rounds.length,
        players: appState.tournament.players.length,
      });
    } catch (e) {
      console.error("Failed to save app state to Heap: " + e);
    }
  }
}
