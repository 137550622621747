import { Round } from "../model/Round";
import { Match } from "../model/Match";
import { Tournament } from "../model/Tournament";
import { MatchHelper } from "./MatchHelper";
import { RoundHelper } from "./RoundHelper";
import { groupBy, shuffle } from "../../utils/ArrayUtils";
import { Player } from "../model/Player";
import { ScoreHelper } from "./ScoreHelper";
import * as Pairings from "tournament-pairings";
import { ResistanceHelper } from "./ResistanceHelper";
import { convertPlayers, sortSwissMatches } from "./AlgorithmHelper";
import _ from "lodash";

export class MatchMakingHelper {
  public static createRound(tournament: Tournament): Round {
    let resistances = ResistanceHelper.getSortedStandings(tournament);
    let playingPlayers = resistances.filter((r) => !r.player.dropped);

    let matches: Match[] = [];
    let byes: string[] = [];
    try {
      let swissPlayers = convertPlayers(playingPlayers);
      let swiss = Pairings.Swiss(swissPlayers, tournament.rounds.length);
      let sorted = sortSwissMatches(tournament.players, swiss);

      sorted.forEach((match) => {
        if (match.player2 === null) {
          byes.push(match.player1 as string);
        } else {
          matches.push(MatchHelper.create(match.player1 as string, match.player2 as string));
        }
      });
    } catch (error) {
      console.error(error);
      console.log("Fallback functionality, shuffling players.");

      let shuffled = shuffle(playingPlayers);
      let chunked = _.chunk(shuffled, 2);
      chunked.forEach((pair) => {
        if (pair[1]) {
          matches.push(MatchHelper.create(pair[0].player.id, pair[1].player.id));
        } else {
          byes.push(pair[0].player.id);
        }
      });
    }

    return RoundHelper.create(matches, byes);
  }

  public static orderByPoints(players: Player[]): Player[] {
    // Group players based on points
    let grouped = groupBy(players, (p) => ScoreHelper.calculatePoints(p.score));

    // Randomize all sub groups
    let finalOrder: Player[] = [];
    grouped.forEach((values) => {
      let subOrder = shuffle(values);
      subOrder.forEach((r) => finalOrder.push(r));
    });

    return finalOrder;
  }
}

/*

Here's a breakdown of the tiebreaker hierarchy. Tiebreakers are considered
from the top down; the first tiebreaker is used, then if the tie remains,
the next tiebreaker is considered. This goes all the way down the list.

1. Late Penalty
2. Opponent's Resistance
3. Opponent's Opponent's Resistance
4. Head-to-Head
5. Random

The zeroth tiebreaker, as I like to call it, is the Late Penalty. If someone
shows up late, then that person will appear below all others with the same
number of match points.

Opponent's Resistance and Opponent's Opponent's Resistance are the two most
known tiebreakers, and for good reason: they always appear in final standings.
These numbers are calculated by summing your opponent's score and then taking
the average of all opponents. These rules dictate the calculation of
resistance:

+ SEE ResistanceUtil.ts for resistance calculation.

Byes are not factored into any calculation. If you received a Bye in Round 1
of a three-round tournament, your Opponent's Resistance would be calculated
from your Round 2 and Round 3 opponents. In another scenario, if one of your
opponents received a Bye and finished 2-1, then they would contribute a 1-1
score (50%) to your Opponent's Resistance.

The resistance score of a dropped opponent is determined by their final record.
If they dropped at 1-2, their resistance score is 33.3%.

Your overall Opponent's Resistance is the average of these scores. If the
resistance scores are 35%, 50%, 60%, and 75%, then your Opponent's Resistance
is 55%.

In large tournaments, tiebreakers usually never get past these two. And in most
cases, Opponent's Resistance is enough. That's because there are many more
possible scores with each additional round. Think of all the possible records
with nine rounds, then compare that with only three rounds. Now factor in nine
opponents vs. three opponents, and you'll see how drastically precise nine
rounds are in comparison with three rounds.

The next tiebreaker is called Head-to-Head. This comes into effect when two
players are tied and have played each other in the tournament. The winner from
that match is placed above the loser. If these players tied, then Head-to-Head
wouldn't come into effect.

The final tiebreaker is a random choice. If all other tiebreakers have been
exhausted and the tie remains, the order is resolved randomly.

*/
